.TopNav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
    padding: 1rem 0;
    z-index: 10;
    position: sticky;
    top: 0;
    right: 0;
    width: 100%;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                               supported by Chrome, Edge, Opera and Firefox */
}

.user {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-right: 2rem;
}
.user p {
    margin: 0 !important;
    padding: 0 !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.user img {
    width: 2.3rem;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                               supported by Chrome, Edge, Opera and Firefox */
}
.user button {
    cursor: pointer;
    background-color: inherit;
    /* border: none; */
    font-size: 1.3rem;
    border: 1px solid #ccc;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    place-content: center;
}
.cancel {
    background-color: red;
    color: white;
}
.notification-btn {
    position: relative;
}
.notification-btn > div {
    position: absolute;
    top: 30px;
    right: 5px;
    width: 350px;
    border-radius: 0.42rem;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    list-style: none;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.15);

    z-index: 10;
}
.notification-btn button {
    border: none;
}
.count {
    position: absolute;
    top: -2px;
    right: 0;
    color: white;
    background-color: rgb(157, 72, 72);
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 5px;
    display: flex;
    font-size: 0.8rem;
    align-items: center;
    justify-content: center;
}
.notification_container {
    /* transform: translate3d(-310px, 40px, 0px); */
    animation: animation-dropdown-menu-fade-in 0.3s ease 1,
        animation-dropdown-menu-move-up 0.3s ease-out 1;
    height: 100%;
    max-height: 400px;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.notification_container::-webkit-scrollbar {
    /* display: none; */
    width: 5px;
}

.notification_container ul {
    text-decoration: none;
    list-style: none;
    display: grid;
    gap: 0.3rem;
    background-color: #ccc;
}
.notification_container ul li {
    display: flex;
    gap: 0.5rem;
    align-items: baseline;
    padding: 0.5rem;
    border-left: 2px solid #1e87bd;
    background-color: white;
}
.right p {
    font-size: 0.85rem;
}
.loading {
    background-color: white;
    display: grid;
    gap: 0.2rem;
    align-items: center;
    justify-items: center;
}
@keyframes animation-dropdown-menu-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes animation-dropdown-menu-move-up {
    0% {
        margin-top: 10px;
    }
    100% {
        margin: 0;
    }
}
