.Skeleton-Container {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    padding: 1rem;
}
.contact {
    display: flex;
    gap: 1rem;
}
.skeleton-left {
    border-right: 1px solid lightgray;
    padding: 0 1rem;
}
.skeleton-rightside {
    padding-left: 1rem;
}
.content {
    padding: 1rem;
    border: 1px solid #ddd;
}
.profile-data {
    margin-top: 1rem;
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    align-items: baseline;
    gap: 1rem;
}
.data-wrap {
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    border-radius: 2px;
    border: 1px solid #ddd;
    margin: 5px 0 10px;
    background: #fff;
}
.data-wrap h3,
.data-wrap p {
    padding: 0.5rem;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.data-wrap header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.data-wrap header button {
    cursor: pointer;
    text-align: center;
    height: fit-content;
    color: #fff;
    background: #186bae !important;
    color: white;
    border: none;
    padding: 2px 6px;
    margin-left: 2px;
    font-size: 0.9rem;
    border-radius: 5px;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-weight: 500;
}
.data-wrap p {
    font-weight: 400;
    font-size: 0.8;
}

.data-wrap span {
    padding: 0.5rem;
}
.files {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.Documents {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: baseline;
}
.skills table {
    width: 100%;
    border-collapse: collapse;
}
.skills table th {
    padding: 0.5rem;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.skills table tbody tr {
    border: none;

    border-bottom: 1px solid #ddd;
}
.skills table tbody tr:last-child {
    border-bottom: none;
}
.skills tbody tr td {
    padding: 0.5rem;
}
.skills tbody tr td:last-child span {
    padding: 0.25em 0.4em;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    background-color: #016997 !important;
    width: fit-content;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
}
.qualification {
    display: grid;
    gap: 0.5rem;
}
.qualification ul {
    padding: 0.5rem;
    display: flex;
    gap: 0.5rem;
    text-decoration: none;
    list-style-type: none;
    align-items: center;
    border-bottom: 1px solid #676a6c;
}
.qualification ul:last-child,
.InstitutionsAttended li:last-child {
    border-bottom: none;
}
.InstitutionsAttended {
    display: grid;
    gap: 0.4rem;
    margin: 0.5rem 2rem;
}
.InstitutionsAttended li {
    color: #676a6c;
    border-bottom: 1px solid #676a6c;
}

.qualification li {
    color: #676a6c;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
}
.qualification li:last-child {
    font-weight: bold;
}
.qualification li span {
    margin: 0 0.3rem 0.1rem 0;
    width: 0.3rem;
    display: inline-block;
    padding: 0;
    height: 0.3rem;
    background-color: #676a6c;
    border-radius: 50%;
}
.updateddocuments {
    padding: 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
}
.updateddocuments img {
    /* width: 100px; */
    height: 100%;
    max-height: 80px;
    cursor: pointer;
}
.skills a {
    text-decoration: none;
    color: #016997;
    text-transform: capitalize;
}
.skills a:hover {
    text-decoration: underline;
}
@media screen and (max-width: 767px) {
    .Skeleton-Container {
        grid-template-columns: 1fr;
        padding: 0;
    }
    .skeleton-left {
        border: none;
    }
    .skeleton-rightside {
        display: none;
    }
    .profile-data,
    .Documents {
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 450px) {
    .data-wrap h3,
    .data-wrap p,
    .skills table th {
        font-size: 4vw;
    }
    .updateddocuments img {
        max-height: 50px;
    }
}
