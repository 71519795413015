.Container h3 {
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.3rem;
    font-size: 15px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    color: #212529;
    text-align: left;
}
.Container table {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top: none;
    border-collapse: collapse;
}
.modal-btn {
    border: none;
    text-decoration: underline;
    cursor: pointer;
    background-color: inherit;
}
.Container table th {
    border: 1px solid rgba(0, 0, 0, 0.125);
    font-size: 15px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    color: #212529;
    text-align: left;
    padding: 0.7rem;
}
.Container table tr td {
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.7rem;
    color: #212529;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
}
/* Default styles */
.modal {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
    background-color: white;
    padding: 0 20px;
    /* height: 100%; */
    max-height: 600px;
    overflow: auto;
    width: 700px;
    overflow: auto;
    /* Media queries for responsive adjustments */
}
.Message-header {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem 0;
}
.message-content h3 {
    font-size: 1rem;
    font-weight: 550;
}
.message-content p {
    /* display: flex; */
    gap: 0.5rem;
    text-align: justify;
}
.Message-header button {
    position: absolute;
    right: 0;
    top: 5px;
    background-color: inherit;
    border: none;
    font-size: 1.1rem;
}
.Message-Container {
    display: grid;
    gap: 0.5rem;
}
.message-content {
    display: grid;
    gap: 0.5rem;
}
/* .Message-header */
.message-content button {
    justify-self: center;
    height: fit-content;
    width: fit-content;
    background-color: #061739;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
}
@media (max-width: 908px) {
    .modal {
        width: 80%;
    }
}
@media (max-width: 480px) {
    .modal {
        width: 60%;
        transform: translate(-40%, -70%);
    }
}

.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    /* Media queries for responsive adjustments */
}
@media (max-width: 480px) {
    .overlay {
        background-color: rgba(0, 0, 0, 0.8);
    }
}
@media screen and (max-width: 500px) {
    .Container table th,
    .Container table tr td,
    .Container h3 {
        padding: 0.3rem;
        font-size: 3.2vw;
    }
}
