.modal {
    /* Default styles */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 0;
    width: 600px;
    overflow: auto;

    /* Media queries for responsive adjustments */
    @media (max-width: 768px) {
        width: 80%;
    }

    @media (max-width: 480px) {
        width: 60%;
    }
}

.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;

    /* Media queries for responsive adjustments */
    @media (max-width: 480px) {
        background-color: rgba(0, 0, 0, 0.8);
    }
}
.wrapper {
    display: grid;
    gap: 0.5rem;
    margin: 0.5rem;
}
.wrapper header {
    border-bottom: 1px solid #ccc;
    padding: 0.3rem;
    position: relative;
    user-select: none;
}
.wrapper header h3 {
    font-size: 1.2rem;
    font-weight: 500;
    font-family: sans-serif;
    text-align: center;
}

.add-item {
    border: 1px dashed #ccc;
    display: grid;
    gap: 0.5rem;
    cursor: pointer;
    align-items: center;
    align-content: center;
    justify-items: center;
    padding: 1rem;
    background-color: rgba(216, 234, 240, 0.5);
    border-radius: 10px;
    height: 8rem;
}
.add-item h5 {
    color: gray;
    font-weight: 400;
    font-size: 1rem;
}
.file-upload {
    width: 100%;
    display: grid;
}

.file-upload-wrapper {
    justify-self: center;
    width: 90%;
}
.upload-icon {
    color: lightskyblue;
    font-size: 2rem;
}
.actionHandler {
    justify-self: center;
    margin-top: 1rem;
}
.wrapper .actionHandler button {
    font-family: sans-serif;
    width: fit-content;
    justify-self: center;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    cursor: pointer;
    background-color: #1e87db;
    color: white;
    border: none;
}
.wrapper header button {
    position: absolute;
    right: -8px;
    top: -9px;
    padding: 0.3rem;
    font-size: 1rem;
    color: white;
    background-color: red;
    cursor: pointer;
    border: none;
}
