.Container {
    padding: 1rem 2rem;
    display: grid;
    gap: 2rem;
    align-content: baseline;
    min-height: 87.3vh;
    height: 100%;
    background-color: #ebf2fa;
}
.wrapper {
    padding: 15px 15px 40px;
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    width: 80%;
}
.wrapper h3 {
    border-bottom: 1px solid #ddd;
    padding: 0 0 5px;
    margin: 0 0 15px;
    font-size: 1.3rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-weight: 500;
    color: #0077b6;
}
.wrapper form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 0.5rem 2rem;
}
.form-control {
    display: grid;
    gap: 0.5rem;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
}
.form-control label {
    font-family: inherit;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.form-control input,
.form-control select {
    display: block;
    width: 95%;
    height: 1.6rem;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    outline: none;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
}
.form-control input:focus,
.form-control select:focus {
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);

    border: 1px solid rgba(81, 203, 238, 1);
}
.form-control span {
    font-family: inherit;
    color: red;
}
.form-control input:disabled {
    background-color: lightgray;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.form-control select {
    height: 2.4rem;
    width: 100%;
}
/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.wrapper form button {
    border: 1px solid #0077b6;
    color: #212529;
    background-color: #fff;
    display: inline-block;
    font-weight: 400;
    color: #0077b6;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    padding: 0.375rem 0.75rem;
    margin-top: 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: fit-content;
    height: fit-content;
    grid-column: 1/-1;
    justify-self: center;
}
.wrapper form button:disabled {
    background-color: white;
    border: none;
    width: 6rem;
    display: grid;
    align-items: center;
    justify-items: center;
}

.indicator {
    padding: 0 !important;
    background-color: inherit !important;
}
.valueContainer {
    padding: 0 !important;
    font-family: Arial, Helvetica, sans-serif !important;
}
.input {
    padding: 0 !important;
    font-family: Arial, Helvetica, sans-serif !important;
}
.singleValue,
.option {
    font-size: 1rem !important;
    font-family: Arial, Helvetica, sans-serif !important;
}
.indicationContainer {
    color: black !important;
    padding: 1px !important;
}
@media screen and (max-width: 700px) {
    .Container {
        padding: 0.8rem;
    }
    .wrapper {
        width: 90%;
    }
    .wrapper form {
        grid-template-columns: 1fr;
    }
    .form-control input {
        width: 90%;
    }
}
