.over-view {
    border: 1px solid #ddd;
    display: grid;
}

.over-view header {
    display: grid;
    padding: 0.5rem;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #ddd;
}
.over-view header > h3 {
    grid-column: 1/-1;
}
.over-view h3,
.over-view header div h4,
.over-view header div p {
    background-color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.over-view header h4 {
    font-weight: 400 !important;
}
.header-left div,
.header-right div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 0.5rem;
}

.review {
    display: grid;
    gap: 0.5rem;
    border: 1px solid #ddd;
    border-top: none;
}
.review > p {
    color: #888;
    padding: 0.5rem;
}
