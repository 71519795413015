.modal {
    /* Default styles */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 500px;
    overflow: auto;
    animation: bounce 0.5s ease;
    /* animation-fill-mode: forwards; */
    /* opacity: 0; */
}
.form-control-one input {
    height: 2.7rem;
    border: 1px solid #ccc;
    font-size: 1rem;
    color: black;
    width: 100%;
    border-radius: 5px;
    text-indent: 1rem;
    outline: none;
}
@media (max-width: 768px) {
    .modal {
        width: 80%;
    }
}
@media (max-width: 480px) {
    .modal {
        width: 60%;
    }
}

.thankyou {
    width: 700px;
    padding: 0;
    outline: none;
}
@media screen and (max-width: 786px) {
    .thankyou {
        width: 90%;
    }
}

.thankyou-header {
    outline: none;
    background-position: center;
    background-size: cover;
    height: 250px;
    display: grid;
    justify-items: center;
    align-items: center;
    align-content: baseline;
    padding: 2rem;
    gap: 0.5rem;
    position: relative;
}
.thankyou-header button {
    position: absolute;
    right: 10px;
    top: 10px;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
}
.thankyou-header img {
    max-width: 150px;
    width: 90%;
}
.thankyou-header h3 {
    color: #f71735;
    font-size: 30px;
}
.thankyou-header p {
    font-weight: 550;
    font-size: 20px;
    text-align: center;
}

.body {
    background: #fffaf4;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 1rem;
    gap: 2rem;
}
.body h3 {
    font-size: 20px;
    text-align: center;
}
.body .Links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}
.body .Links a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #d34fba;
    border: 1px solid #d34fba;
    padding: 0.5rem;
    font-size: 1rem;
}
.body .Links a:hover {
    color: white;
    background-color: #d34fba;
}

.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    /* Media queries for responsive adjustments */
}
@media (max-width: 480px) {
    .overlay {
        background-color: rgba(0, 0, 0, 0.8);
    }
}

.form-wrapper {
    display: grid;
    align-items: center;
    gap: 1rem;
    width: 100%;
    position: relative;
}
.form-wrapper > button {
    position: absolute;
    right: -10px;
    top: -10px;
    font-size: 1.5rem;
    border: none;
    background-color: inherit;
    cursor: pointer;
}
.form-wrapper h2 {
    text-align: center;
    font-size: 1.3rem;
}
.form-wrapper form {
    display: grid;
    gap: 1rem;
}
.form-wrapper form .form-control {
    position: relative;
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    gap: 1rem;
    width: 100%;
}
.form-wrapper form .form-control label {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    font-size: 1.1rem;
}
.form-wrapper form .form-control textarea,
.form-wrapper form .form-control .input input {
    border: 1px solid #ccc;
    outline: none;
    resize: none;
    font-size: 1rem;
    height: 5rem;
    width: 100%;
    width: 90%;
    font-family: inherit;
    text-indent: 0.4rem;
}
.form-wrapper form .form-control .input input {
    height: 2.5rem;
}
.form-wrapper form .form-control .error {
    color: red;
}
.form-wrapper form .form-control .radio-buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.form-wrapper form .form-control .radio-buttons label {
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    gap: 0.5rem;
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
    justify-self: center;
}

.submit {
    background-color: #1e87db;
    color: white;
    text-align: center;
    border-radius: 10px;
    border: 1px solid;
    cursor: pointer;
    padding: 0 1rem;
    height: 2.5rem;
}

.close {
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    border: 1px solid lightgray;
    background-color: lightgray;
    border-radius: 5px;
    color: black;
    padding: 0 1rem;
    height: 2.5rem;
}

@keyframes bounce {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
        transform: translate(-50%, -50%) scale(0.95);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}
@media screen and (max-width: 700px) {
    .form-control {
        display: grid;
        grid-template-columns: 1fr !important;
        gap: 0.5rem !important;
    }
    .modal {
        width: 60%;
    }
}
@media screen and (max-width: 500px) {
    .form-wrapper form input,
    .form-wrapper form select {
        width: 100% !important;
    }
}
@media screen and (max-width: 786px) {
    .thankyou-header {
        height: unset;
        justify-items: center;
    }
    .thankyou-header img {
        width: 100px;
    }
    .thankyou-header h3 {
        font-size: large;
    }
    .thankyou-header p {
        font-size: small;
    }
    .body h3 {
        font-size: large;
    }
    .body .Links a {
        font-size: small;
    }
}
