.Container {
    border: 1px solid #ddd;
}
.Container h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.3rem;
    font-size: 15px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    color: #212529;
    text-align: left;
}
/* .Container p {
    padding: 1rem 1rem 0 1rem;
} */
.Container header {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
}
.Container header h4 {
    background-color: inherit;
    font-weight: 500;
}
.Container header div {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}
.Container header div button {
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    cursor: pointer;
}
.Container header div button:disabled {
    color: lightgray;
    cursor: not-allowed;
}

.Container header h4 span {
    font-weight: bold;
    font-size: 0.9rem;
    padding: 0.3rem;
    color: white;
    border-radius: 10px;
    background-color: rgb(78, 131, 78);
}

.form {
    display: flex;
    flex: 1;
    gap: 1rem;
    align-items: center;
}
.form input {
    text-indent: 0.3rem;
    border: 1px solid lightgray;
    height: 2rem;
    border-radius: 10px;

    width: 100%;
    max-width: 7rem;
    /* font-size: 1rem; */
    text-transform: uppercase;
}
.form button {
    color: white;
    border: 1px solid #0077b6;
    background-color: #0077b6;
    width: -moz-fit-content;
    width: fit-content;
    align-self: flex-end;
    padding: 0.4rem 1rem;
    border-radius: 10px;
    cursor: pointer;
}

.Table {
    display: grid;
    width: 100%;
    overflow: auto;
}
.Table table {
    border-collapse: collapse;
    table-layout: auto;
    /* width: 100%; */
    min-width: 700px;
    text-align: left;
    border: 1px solid #ccc;
}
.Table table thead {
    color: white;
    border-bottom: 1px solid #ccc;
}
.Table table thead th {
    padding: 0.3rem;
    font-size: 15px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    color: #212529;
    text-align: center;
}
.Table table tbody tr {
    text-align: center;
    cursor: pointer;
    color: #777;
}
.Table table tbody tr td {
    border-bottom: 1px solid #ccc;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    padding: 1rem 0rem;
    user-select: none;
    color: inherit;
    cursor: pointer;
    text-transform: capitalize;
}
.Table table tbody tr:hover {
    background-color: #c9c9c9;
    color: white;
}
.paid {
    color: white;
    background-color: rgb(45, 139, 45);
    border-radius: 5px;
    border: 1px solid rgb(45, 139, 45);
    padding: 0.3rem 0.5rem;
}
.unpaid {
    color: white;
    background-color: rgb(187, 86, 86);
    border-radius: 5px;
    border: none;
    padding: 0.2rem 0.5rem;
}
.loading td {
    padding: 1rem 0.5rem !important;
}
