.Container {
    padding: 1rem 2rem;
    display: grid;
    gap: 1rem;
    align-content: baseline;
    min-height: 87.3vh;
    height: 100%;
    background-color: #ebf2fa;
}
.Container > h3,
.sales header h3 {
    font-size: 1.5rem;
    display: block;
    font-weight: 500;
    line-height: 1.2;
    font-family: inherit;
    color: #212529;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-align: left;
}
.form {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
}
.form input {
    text-indent: 0.3rem;
    border: 1px solid lightgray;
    height: 2rem;
    border-radius: 10px;

    width: 100%;
    max-width: 7rem;
    /* font-size: 1rem; */
    text-transform: uppercase;
}
.form button {
    color: white;
    border: 1px solid #0077b6;
    background-color: #0077b6;
    width: -moz-fit-content;
    width: fit-content;
    align-self: flex-end;
    padding: 0.6rem 1rem;
    border-radius: 10px;
    cursor: pointer;
}
.analytics {
    grid-gap: 2rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.analytics_card {
    background-color: #fff !important;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 60px rgb(0 0 0/8%);
    display: grid;
    gap: 0.6rem;
    grid-template-columns: 1fr;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-width: 25rem;
    padding: 1rem;
}
.analytics_card h3 {
    font-size: 1.2rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    font-weight: 400;
    text-align: center;
}
.analytics_card p {
    font-weight: 550;
    font-size: 1.4rem;
    text-align: center;
}

/* ----------------- */
.sales header h3 {
    color: #355ba1;
    /* text-transform: uppercasez   ; */
}
.admin_Container {
    user-select: none;
    display: grid;
    gap: 1rem;
}
.Summary_container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.sales {
    display: grid;
    gap: 1rem;
}
.sales header {
    display: flex;
    justify-content: space-between;
    /* flex-direction: ; */
    gap: 1rem;
    align-items: center;
}
.sales header select {
    border: 1px solid #ccc;
    height: 100%;
    padding: 0 1rem;
    border-radius: 10px;
}
.summary {
    width: 100%;
    max-width: 260px;
    position: relative;
    overflow: hidden;
    padding: 16px;
    height: 4rem;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 10px;
    border: 1px solid rgb(224, 224, 227);
    background-clip: padding-box;
    box-shadow: none;
}
.summary::before {
    position: absolute;
    top: 50%;
    left: 0px;
    display: block;
    content: "";
    height: 200%;
    aspect-ratio: 1 / 1;
    transform: translate(-40%, -60%);
    border-radius: 50%;
    background-color: #355ba1;
    /* opacity: 0.4; */
}
.summary > p {
    color: white;
    z-index: 2;
    text-align: center;
    width: 5rem;
}
.summary_text {
    display: flex;
    flex-direction: column;
}
.summary_text h3 {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.6);
}
.counter {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
    align-self: flex-end;
}
.Charts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 1rem;
}
.chart {
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    background-color: white;
    gap: 1rem;
    padding: 1rem 0;
    border-radius: 5px;
    height: 100%;
    max-height: 310px;
    position: relative;
}
.chart h3 {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 550;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #355ba1;

    text-align: center;
}
.date-picker {
    position: absolute;
    top: 2%;
    right: 2%;
}
.date-picker-container {
    border: 1px solid #ccc;
    outline: none;
    height: 1.8rem;
    /* width: 5rem; */
    width: 100%;
    max-width: 6rem;
    text-align: center;
    border-radius: 10px;
    /* text-indent: 1rem; */
    font-family: inherit;
}

.tables_Container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
}
.table {
    background-color: white;
    display: grid;
    border-radius: 5px;
    padding: 0.5rem;
}
.table h3 {
    font-weight: 500;
    font-family: inherit;
    color: #0077b6;
    margin-bottom: 1rem;
    text-align: center;
}
.table table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #ebf0ed;
    border-radius: 30px;
}
.table table th {
    padding: 0.5rem 0;
    text-transform: uppercase;
    text-align: left;
    color: #6b6b6b;
    font-weight: 500;
    font-family: inherit;
    font-size: 0.9rem;
    text-align: center;
}
.table table thead tr {
    border-bottom: 1px solid #ebf0ed;
}
.table table div {
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
}
.table table img {
    border-radius: 50%;
    width: 2.5rem;
}
.table table tbody tr td {
    padding: 0.5rem 0;
    text-align: center;
}
.table button {
    justify-self: right;
    border: none;
    cursor: pointer;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: inherit;
    color: #0077b6;
    font-weight: 550;
}
