.container {
    padding: 1rem 2rem;
    display: grid;
    gap: 1rem;
    align-content: baseline;
    min-height: 87.3vh;
    height: 100%;
    background-color: #ebf2fa;
}
.back {
    width: fit-content;
    border: 1px solid #ccc;
    padding: 0.5rem;
    background-color: white;
    color: black;
    cursor: pointer;
}
.back:hover {
    background-color: lightgray;
}
.wrapper {
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    align-content: baseline;
    gap: 1rem;
    padding: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    width: 80%;
    height: 100%;
    min-height: 18rem;
}
.action-btns {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.action-btns button {
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: inherit;
    height: 2.3rem;
    display: flex;
    gap: 0.5rem;
    color: #0077b6;
    padding: 0 1rem;
    align-items: center;
}
.header {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
    position: relative;
    margin-top: 1rem;
}
.cancel {
    background-color: red;
    color: white;
}
.header > button {
    color: white;
    background-color: red;
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
    border: none;
    cursor: pointer;
}
.wrapper h3 {
    display: flex;
    /* align-items: center; */
    gap: 1rem;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-weight: 500;
    color: #0077b6;
    text-transform: capitalize;
}
.wrapper h3 span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    background-color: SandyBrown;
}
.profile {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.initials {
    border: 1px solid #ddd;
    height: 90px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0.5rem; */
}
.initials h4 {
    padding: 5px;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 2.9rem;
    font-weight: 400;
    text-align: center;

    text-transform: uppercase;
}
.initials img {
    width: 100%;
    height: 100%;
}
.Contact {
    padding: 0.5rem;
    display: flex;
    gap: 1.4rem;
    border-right: 1px solid #ddd;
}
.card-data h4 {
    margin-bottom: 0.5rem;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
}
.card-data {
    display: grid;
    align-items: start;
    align-content: start;
}
.card-data p:nth-child(2) {
    color: #016997;
    margin-bottom: 0.2rem;
}
.card-data p:nth-child(2) span {
    margin-bottom: 0.2rem;
}
.card-data p {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}
.action-btn {
    width: 100px;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    justify-content: center;
    margin-top: 0.4rem;
    cursor: pointer;
    padding: 0.3rem 0;
    color: #fff;
    background: #186bae !important;
    font-size: 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid transparent;
}
.internal {
    padding: 0.5rem;
    display: grid;
    align-content: start;
    gap: 0.3rem;
}
.internal > h3 {
    font-weight: 700;
    font-size: 1rem;
    color: #212529;
    text-align: left;
}
.internal h2 {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-weight: 500;
    color: #0077b6;
    text-transform: capitalize;

    margin: 1rem 0;
}
.internal div {
    display: grid;
    grid-template-columns: 0.4fr 0.7rem 1fr;
    /* align-items: center; */
}
.internal div h3,
.internal p {
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.internal p {
    font-weight: 600;
    word-wrap: break-word;
    word-break: break-all;
}
.internal p button {
    border: none;
    cursor: pointer;
    background-color: inherit;
}
.Table {
    display: grid;
    width: 100%;
    overflow: auto;
}
.Table h3 {
    margin-bottom: 1rem;
}
.Table table {
    border-collapse: collapse;
    table-layout: auto;
    /* width: 100%; */
    min-width: 700px;
    text-align: left;
    border: 1px solid #ccc;
}
.Table table thead {
    color: white;
}
.Table table thead th {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    background-color: #1e87db;
    padding: 1rem 0rem;
    /* letter-spacing: 0.05rem; */
    text-transform: uppercase;
    vertical-align: middle;
    text-align: center;
}
.Table table tbody tr {
    text-align: center;
    cursor: pointer;
    color: #777;
}
.Table table tbody tr td {
    border-bottom: 1px solid #ccc;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    padding: 1rem 0rem;
    color: inherit;
    cursor: pointer;
    text-transform: capitalize;
}
.Table table tbody tr:hover {
    background-color: #c9c9c9;
    color: white;
}
.completed {
    color: white;
    background-color: green;
    padding: 0.5rem;
    border-radius: 10px;
}
.cancelled {
    color: white;
    border-radius: 10px;
    padding: 0.5rem;
    background-color: red;
}
.Notifications {
    border: 1px solid #c9c9c9;
}
.Notifications h3 {
    color: gray;
    border-bottom: 1px solid #c9c9c9;
    font-size: 1.1rem;
    padding: 0.5rem;
}
.Notifications ul {
    list-style-type: none;
}
.Notifications ul li {
    border-bottom: 1px solid #ccc;
    padding: 0.5rem;
    color: gray;
    cursor: pointer;
}
.Notifications ul li:hover {
    background-color: lightgray;
}
.Notifications ul li::before {
    content: "•  ";
    font-size: 1rem;
}
.reschedule {
    background-color: darkgreen !important;
    border: 1px solid darkgreen !important;
}
.header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-wrapper select {
    /* height: 2rem; */
    padding: 0.5rem;
    border: 1px solid #ccc;
    cursor: pointer;
    outline: none;
}
.header-wrapper > p {
    color: white;
    padding: 0.5rem 0.7rem;
    font-size: 0.9rem;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s;
    right: 0;
}
.rescheduled {
    background-color: darkgreen;
    border: 1px solid darkgreen;
}
.requested {
    background-color: orange;
    border: 1px solid orange;
}
.rejected {
    background-color: red;
    border: 1px solid red;
}
@media screen and (max-width: 900px) {
    .profile {
        grid-template-columns: 1fr;
    }
    .Contact {
        border: none;
    }
    .internal div {
        width: 90%;
    }
    .header-wrapper {
        display: grid;
        width: 100%;
    }
    .header-wrapper > p {
        grid-row: 1/2;
        justify-self: right;
    }
}
@media screen and (max-width: 500px) {
    .Contact {
        display: grid;
    }
}
