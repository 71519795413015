.Container {
    display: grid;
    align-items: center;
    gap: 1rem;
}
.jobs,
.applications {
    border: 1px solid #ddd;
}
.jobs h3,
.charges h3,
.jobs header div h4,
.jobs header div p,
.applications h3,
.applications header div h4,
.applications header div p,
.Activity-feed h3 {
    background-color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.jobs header,
.applications header {
    display: grid;
    padding: 0.5rem;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #ddd;
}
.jobs header > h3,
.applications header > h3 {
    grid-column: 1/-1;
}
.header-left {
    border-right: 1px solid #ddd;
}
.header-left div,
.header-right div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 0.5rem;
}

.jobs header div h4,
.applications header div h4 {
    font-weight: 400;
}
.jobs header div p {
    font-weight: 700;
}
.sub-nav {
    display: flex;
    border-bottom: 1px solid #ddd;
}
.sub-nav button {
    /* border-width: 1px 1px 0 0; */
    border: none;
    border-radius: 0;
    padding: 14px 20px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    border-right: 1px solid #ddd;
}
.Active {
    background-color: white;
}
.Assigned-Jobs {
    display: grid;
}
.table-container {
    display: grid;
    width: 100%;
    /* min-width: 500px; */
    overflow: auto;
}
.table-container table {
    border-collapse: collapse;
    overflow: scroll !important;
    word-wrap: break-word;
}

.table-container th {
    border: 1px solid #ddd;
    padding: 0.5rem;
    font-size: 0.9rem;
    text-align: left;
}
.table-container td {
    border: 1px solid #ddd;
    /* white-space: nowrap; */
    padding: 0.5rem;
}
.table-container a {
    color: #0076b6;
    text-decoration: none;
}
.table-container a:hover {
    text-decoration: underline;
}
.inprogress,
.finished {
    background-color: #1e87db;
    color: white;
    width: fit-content;
    font-weight: 500;
    padding-right: 0.6em;
    padding-left: 0.6em;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.finished {
    background-color: #83aa68;
}
.applications .header-right div {
    grid-template-columns: 1fr 1fr !important;
}
.charges-tasks {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1rem;
}
.charges,
.Activity-feed {
    display: grid;
    border: 1px solid #ddd;
    flex-grow: 1;
}
.charges h3,
.Activity-feed h3 {
    padding: 0.3rem;
    border-bottom: 1px solid #ddd;
}
.charges p {
    margin: 5px 10px;
    font-size: 90%;
    color: #888;
}
.Activity-feed p {
    padding: 0.3rem;
    color: #551a8b;
    font-size: 1rem;
    border-bottom: 1px solid #ddd;
}
.Activity-feed p:last-child {
    border: none;
}
.Activity-feed a {
    text-decoration: none;
}
.Activity-feed ul {
    list-style-type: none;
    padding: 0.3rem;
}

.Activity-feed li {
    padding: 0.3rem 0;
    border-bottom: 1px solid #ddd;
    display: grid;
    justify-content: space-between;
    align-content: baseline;
    gap: 0.2rem;
}
.Activity-feed li span:nth-child(2) {
    font-size: 0.8rem;
    color: gray;
}
.Activity-feed li:last-child {
    border: none;
}
.Activity-feed a:hover {
    text-decoration: underline;
}
@media screen and (max-width: 980px) {
    .jobs header,
    .applications header {
        grid-template-columns: 1fr;
    }
    .header-left {
        border: none;
    }
    .header-right {
        padding: 0;
    }
    .charges-tasks {
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 500px) {
    .Container h3,
    .Container p,
    .Container h4,
    .sub-nav button,
    .Container a,
    .table-container {
        font-size: 4vw !important;
    }
}
