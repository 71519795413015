.modal {
  /* Default styles */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0;
  width: 600px;
  overflow: auto;
  /* Media queries for responsive adjustments */
}
@media (max-width: 768px) {
  .modal {
    width: 80%;
  }
}
@media (max-width: 480px) {
  .modal {
    width: 50%;
  }
}

.wrapper {
  display: grid;
  gap: 0.5rem;
  margin: 0.5rem;
}

.overlay {
  /* Default styles */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  /* Media queries for responsive adjustments */
}
@media (max-width: 480px) {
  .overlay {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.wrapper header {
  border-bottom: 1px solid #ccc;
  padding: 0.5rem;
}

.wrapper header h3 {
  font-size: 1.2rem;
  font-weight: 500;
  font-family: sans-serif;
  text-align: center;
}

.wrapper header button {
  border: none;
  background-color: inherit;
  font-size: 1rem;
  cursor: pointer;
}

.wrapper form {
  display: grid;
  gap: 0.5rem;
  padding: 0 1rem;
}

.form-control {
  display: grid;
  gap: 0.3rem;
}

.form-control > label {
  font-size: 0.95rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
}

.form-control select {
  height: 2.5rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 100%;
  outline: none;
}

.form-control textarea {
  border: 1px solid #ccc;
  outline: none;
  height: 4.5rem;
  text-indent: 0.5rem;
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  resize: none;
}

.Control-btns {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
  justify-self: center;
}

.Control-btns button {
  width: 4rem;
  height: 2rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  border: none;
  color: white;
}

.Control-btns button:first-child {
  background-color: red;
}

.Control-btns button:nth-child(2) {
  background-color: rgb(48, 48, 140);
}

.error {
  color: red;
  font-size: 0.9rem;
  font-weight: 550;
}

.input-error {
  border: 1px solid red !important;
}/*# sourceMappingURL=popup.module.css.map */