.event-container {
    background-color: #333;
    border-radius: 4px 4px 0 0;
    color: white;
    display: grid;
    gap: 0.5rem;
}
.event-container h3 {
    border-bottom: 1px solid #ebebeb;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    padding: 8px 14px;
}
.content {
    padding: 0 10px;
    display: grid;
    gap: 0.3rem;
}
.content p {
    font-weight: bolder;
    font-size: 12px;
}
.content p span {
    font-size: 11px;
    font-weight: 400;
}
.actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 5px 5px 5px;
}
.event-container button {
    border: none;
    background-color: inherit;
    color: #d838ff;
    font-size: 12px;
    cursor: pointer;
}

.calender-wrapper {
    display: grid;
    background-color: white;
    gap: 0.5rem;
}
.calender-wrapper h3 {
    font-size: 1rem;
    padding: 0.5rem;
    display: block;
    font-weight: 500;
    line-height: 1.2;
    font-family: inherit;
    color: white;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-align: left;
}
.calender {
    background-color: white;
    width: calc(100vw-300px);
    padding: 1rem;
    overflow: hidden;
    transition: all 1s;
}
.search {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    flex: 1;
    margin: 0.1rem 0;
    box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.1);
}
.form-control {
    max-width: 250px;
    width: 95%;
    position: relative;
    display: grid;
    gap: 0.5rem;
    z-index: 5;
}
.form-control input,
.form-control select,
.select {
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    height: 2.5re3;
    /* padding: 8px; */
    width: 100%;
    cursor: pointer;
}

/* @media screen and (max-width: 1140px) {
    .calender-wrapper {
        grid-template-columns: 70% 1fr;
    }
} */
@media screen and (max-width: 900px) {
    .calender-wrapper {
        grid-template-columns: 1fr;
    }
    .search {
        grid-column: 1/-1;
        grid-row: 1/2;
    }
}
@media screen and (max-width: 800px) {
    .calender {
        width: 100%;
        max-width: 600px;
        overflow: hidden;
    }
}
.status-cancelled {
    color: red;
    font-weight: 550;
    font-size: 12px;
    color: white;
}

.status-pending {
    color: burlywood;
    font-weight: 550;
    font-size: 12px;
    color: white;
}

.status-confirmed {
    color: rgba(4, 135, 54, 0.96);
    font-weight: 550;
    font-size: 12px;
    color: white;
}
