.profile-data {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
    gap: 1rem;
}
.profile-data div {
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    border-radius: 2px;
    border: 1px solid #ddd;
    margin: 5px 0 10px;
    background: #fff;
    width: 95%;
}
.profile-data div h3 {
    padding: 0.5rem;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #0077b6;
    text-align: left;
}

.profile-data div p span {
    color: #777 !important;
}
/* .profile-data div span {
    padding: 0.5rem;
} */
.profile-data div p a {
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    max-width: 17rem;
    width: 100%;
    display: inline-block;
}
.Activity-feed p {
    padding: 0.3rem;
    color: #551a8b;
    font-size: 0.9rem;
    border-bottom: 1px solid #ddd;
}
.Activity-feed p:last-child {
    border: none;
}
.Activity-feed a {
    text-decoration: none;
}
.Activity-feed ul {
    list-style-type: none;
    padding: 0.3rem;
}

.Activity-feed li {
    padding: 0.3rem 0;
    font-size: 0.9rem;
    border-bottom: 1px solid #ddd;
    display: grid;
    justify-content: space-between;
    align-content: baseline;
    gap: 0.2rem;
}
.Activity-feed li span:nth-child(2) {
    font-size: 0.8rem;
    color: gray;
}
.Activity-feed li:last-child {
    border: none;
}
.Activity-feed a:hover {
    text-decoration: underline;
}
@media screen and (max-width: 767px) {
    .profile-data {
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 600px) {
    .profile-data div p span {
        display: none;
    }
    .profile-data div p a {
        width: 100%;
    }
    .profile-data div h3,
    .profile-data div p {
        padding: 0;
        padding-left: 0.1rem;
    }
}
