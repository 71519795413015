.Container {
    padding: 1rem 2rem;
    display: grid;
    gap: 2rem;
    align-content: baseline;
    min-height: 87.3vh;
    height: 100%;
    background-color: #ebf2fa;
}
.control-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    border: 1px solid #ccc;

    background-color: white;
    color: #0077b6;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 1rem;
}
.button:hover {
    background-color: #ececec;
}
.Tutor {
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    width: 90%;
}
.Tutor h3 {
    /* border-bottom: 1px solid #ddd; */
    background-color: #1e87db;
    padding: 0.3rem 1rem;
    color: white;
    font-size: 1.3rem;
    display: block;
    font-weight: 500;
    line-height: 1.2;
    font-family: inherit;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-align: left;
}
.Tutor > h2 {
    background-color: #1e87db;
    color: #fff;
    font-size: 14px;
    width: fit-content;
    padding: 0.3rem 0.5rem;
    text-align: center;
    border-radius: 5px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
}
.Navigator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.085);
}
.Navigator button {
    min-height: 46px;
    padding: 12px 16px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 400;
    background-color: white;
    cursor: pointer;
    color: #22527b;
}
.Navigator button:hover {
    border-color: #dee2e6 #dee2e6 #f9f9f9;
}
.Navigator button span {
    margin-left: 4px;
    background-color: #777;
    color: #fff;
    font-weight: 500;
    border-radius: 10rem;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}
.active {
    border-color: #dee2e6 #dee2e6 #f9f9f9 !important;
    background-color: #f9f9f9 !important;
}
.cards {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.Table {
    display: grid;
    width: 100%;
    overflow: auto;
}
.Table table {
    border-collapse: collapse;
    table-layout: auto;
    /* width: 100%; */
    min-width: 700px;
    text-align: left;
    border: 1px solid #ccc;
}
.Table table thead {
    color: white;
}
.Table table thead th {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    background-color: #1e87db;
    padding: 1rem 0rem;
    /* letter-spacing: 0.05rem; */
    text-transform: uppercase;
    vertical-align: middle;
    text-align: center;
}
.Table table tbody tr {
    text-align: center;
    cursor: pointer;
    color: #777;
}
.Table table tbody tr td {
    border-bottom: 1px solid #ccc;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    padding: 0.5rem 0rem;
    color: inherit;
    cursor: pointer;
    text-transform: capitalize;
}
/* .Table table tbody tr:hover {
    background-color: #c9c9c9;
    color: white;
} */
.Action-btns {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}
.Action-btns button {
    border: none;
    background-color: inherit;
    font-size: 1.4em;
    color: #0077b6;
    cursor: pointer;
}
.Status-active span {
    background-color: #def7ec;
    color: #0e9f6e;
    padding: 0.5rem;
}
.Status-inactive span {
    background-color: #fde8e8;
    color: #f05252;
}
.schedule-class {
    border: none;
    background-color: inherit;
    color: #1e87db;
    cursor: pointer;
    text-decoration: underline;
}
.Notifications ul {
    text-decoration: none;
    list-style: none;
    display: grid;
    gap: 0.3rem;
    background-color: #ccc;
}
.Notifications ul li {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    /* cursor: pointer; */
    padding: 0.5rem;
    border-left: 2px solid #1e87bd;
    background-color: white;
}
.Notifications ul li:hover {
    background-color: lightgray;
}
.Notifications ul li h5 {
    font-size: 0.9rem;
}
.right p {
    color: gray;
    font-size: 0.85rem;
}
.loading {
    background-color: white;
    display: grid;
    gap: 0.2rem;
    align-items: center;
    justify-items: center;
}
@media screen and (max-width: 600px) {
    .Container {
        padding: 1rem;
    }
}
