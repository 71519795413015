.Container {
    padding: 1rem 2rem;
    display: grid;
    gap: 1rem;
    align-content: baseline;
    min-height: 87.3vh;
    height: 100%;
    background-color: #ebf2fa;
}
.wrapper {
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    align-content: baseline;
    gap: 1rem;
    padding: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    width: 85%;
    height: 100%;
    min-height: 18rem;
}
.back {
    width: fit-content;
    border: 1px solid #ccc;
    padding: 0.5rem;
    background-color: white;
    color: black;
    cursor: pointer;
}
.back:hover {
    background-color: lightgray;
}
.status-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
}
.status-buttons select {
    border-radius: 5px;
    border: 1px solid lightgray;
    outline: none;
    padding: 0.3rem;
}
.status-buttons select option {
    color: black !important;
    background-color: white !important;
}
.status-buttons select option:disabled {
    color: gray !important;
}
.status-buttons button {
    color: white;
    font-size: 14px;
    border: none;
    font-weight: 500;
    letter-spacing: 0.02rem;
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.2rem 0.4rem;
    font-family: Open Sans, sans-serif;
    border-radius: 5px;
}
.Approve-button {
    background-color: #28a745;
}
.reject-button {
    background-color: #dc3545;
}
.dormant-button {
    background-color: #26293a;
}
.cancel {
    background-color: red;
    color: white;
}
.wrapper h3 {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-weight: 500;
    color: #0077b6;
    text-transform: capitalize;
}
.wrapper h3 span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    background-color: SandyBrown;
}
.control-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    border: 1px solid #ccc;

    background-color: white;
    color: #0077b6;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    padding: 8px 18px;
    cursor: pointer;
    font-size: 0.9rem;
}
.button:hover,
.btn-active {
    background-color: #ececec;
}

/* --modal  */

.modal {
    /* Default styles */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;

    width: 100%;
    max-width: 600px;
    overflow: auto;
    animation: bounce 0.5s ease;
    /* // animation-fill-mode: forwards; */
    outline: none;
}
.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;
}
.modal_Container {
    display: grid;
    align-items: center;
    gap: 0.5rem;
    text-align: center;
    width: 100%;
}
.modal_Container header {
    position: relative;
    padding: 5px 10px;
    border-bottom: 1px solid lightgray;
}
.control_Buttons {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}
.control_Buttons button {
    font-size: 1rem;
    border: none;

    color: white;
    justify-self: center;
    width: fit-content;
    margin-bottom: 0.5rem;
    padding: 0.2rem 0.5rem;

    cursor: pointer;
}
.control_Buttons .resolve {
    background-color: #0e9f6e;
}
.control_Buttons .close {
    background-color: red;
}
.modal_Container header h3 {
    text-align: center;
    font-family: "poppins" sans-serif;
    font-weight: 550;
    font-size: 1.3rem;
}
.Complaint_Details {
    display: grid;
    gap: 1rem;

    padding: 0 1rem;
}
.Complaint_Details div {
    padding: 1rem 0;
    display: grid;
    text-align: center;
    /* grid-template-columns: 30% 1fr; */
    align-items: baseline;
    gap: 0.5rem 1rem;
}
.Complaint_Details textarea {
    outline: none;
    resize: none;
    border-color: gray;
    padding: 0.5rem;
    border-radius: 10px;
    font-family: inherit;
}
.loader {
    justify-self: center;
}
.Complaint_Details div h3 {
    font-family: sans-serif;
    font-weight: 550;
    font-size: 1rem;
}
.Complaint_Details div p {
    word-wrap: break-word;
    word-break: break-all;
    text-align: center;
}
.Complaint_Details ul {
    list-style-type: none;
}
.Complaint_Details div:last-child {
    /* padding-bottom: 0.4rem; */
    border-bottom: 1px solid #ddd;
}
.modal:has(.loader) {
    cursor: progress;
}
.pending {
    color: #fde8e8;
    background-color: #d78686;
    width: fit-content;
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
}
.resolved {
    background-color: #def7ec;
    width: fit-content;
    color: #0e9f6e;
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
}
@media screen and (max-width: 768px) {
    .modal {
        width: 80%;
    }
    .Complaint_Details div {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 480px) {
    .modal {
        width: 60%;
    }
    .overlay {
        background-color: rgba(0, 0, 0, 0.8);
    }
}
@media screen and (max-width: 600px) {
    .Container {
        padding: 1rem;
    }
}
@keyframes bounce {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
        transform: translate(-50%, -50%) scale(0.95);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

@media screen and (max-width: 1000px) {
    .wrapper {
        width: 90%;
    }
}
@media screen and (max-width: 500px) {
    .Container {
        padding: 1rem;
    }
    .button {
        font-size: 3.5vw;
        padding: 0.3rem;
    }
    .wrapper > h3 {
        font-size: 4vw;
    }
}
