.Container {
    padding: 1rem 2rem;
    display: grid;
    gap: 2rem;
    align-content: baseline;
    min-height: 87.3vh;
    height: 100%;
    background-color: #ebf2fa;
}
.control-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    border: 1px solid #ccc;
    font-size: 1rem;
    background-color: white;
    color: #0077b6;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    padding: 8px 12px;
    cursor: pointer;
}
.button:hover {
    background-color: #ececec;
}
.Students {
    /* border: 2px solid #bb585b; */
    padding: 15px 15px 40px;
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    width: 90%;
}
.Students h3 {
    border-bottom: 1px solid #ddd;
    padding: 0 0 5px;
    margin: 0 0 15px;
    font-size: 1.3rem;
    display: block;
    font-weight: 500;
    line-height: 1.2;
    font-family: inherit;
    color: #212529;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #0077b6;
    text-align: left;
}
.search {
    flex: 1;
    position: relative;
}
.search-icon {
    position: absolute;
    top: 10px;
    left: 8px;
}
.search input {
    width: 100%;
    max-width: 31rem;
    border: 1px solid #ccc;
    padding: 0.3rem;
    border-radius: 5px;
    height: 2.4rem;
    font-size: 1rem;
    outline: none;
    text-indent: 2rem;
}
.Students h3 span {
    background-color: #0077b6;
    color: #fff;
    border-radius: 10rem;
    display: inline-block;
    padding: 0.25em 0.5em;
    font-size: 0.9rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.Students select {
    display: block;
    padding: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 2.25rem;
    justify-self: right;
    border: 1px solid #ccc;
    outline: none;
    cursor: pointer;
}
.cards {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.cards h2 {
    background-color: #1e87db;
    color: #fff;
    font-size: 14px;
    width: fit-content;
    padding: 0.3rem 0.5rem;
    text-align: center;
    border-radius: 5px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
}
.Table {
    display: grid;
    width: 100%;
    overflow: auto;
}
.Table table {
    border-collapse: collapse;
    table-layout: auto;
    /* width: 100%; */
    min-width: 900px;
    text-align: left;
    border: 1px solid #ccc;
}
.Table table thead {
    color: white;
}
.Table table thead th {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    background-color: #1e87db;
    padding: 1rem 0rem;
    /* letter-spacing: 0.05rem; */
    text-transform: uppercase;
    vertical-align: middle;
    text-align: center;
}
.Table table tbody tr {
    text-align: center;
    cursor: pointer;
    color: #777;
}
.Table table tbody tr td {
    border-bottom: 1px solid #ccc;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    padding: 1rem 0rem;
    color: inherit;
    cursor: pointer;
    text-transform: capitalize;
}
.Table table tbody tr:hover {
    background-color: #c9c9c9;
    color: white;
}
@media screen and (max-width: 500px) {
    .Container {
        padding: 1rem;
    }
}
