.Container {
    padding: 1rem 2rem;
    display: grid;
    gap: 1rem;
    align-content: baseline;
    min-height: 87.3vh;
    height: 100%;
    background-color: #ebf2fa;
}
.back {
    width: fit-content;
    border: 1px solid #ccc;
    padding: 0.5rem;
    background-color: white;
    color: black;
    cursor: pointer;
}
.back:hover {
    background-color: lightgray;
}
.wrapper {
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    align-content: baseline;
    gap: 1rem;
    padding: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    width: 80%;
    height: 100%;
    min-height: 18rem;
}
.wrapper h3 {
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-weight: 500;
    color: #0077b6;
}
.wrapper h3 span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    background-color: SandyBrown;
}
.control-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    border: 1px solid #ccc;

    background-color: white;
    color: #0077b6;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    padding: 8px 18px;
    cursor: pointer;
    font-size: 0.9rem;
}
.button:hover,
.btn-active {
    background-color: #ececec;
}
@media screen and (max-width: 1000px) {
    .wrapper {
        width: 90%;
    }
}
@media screen and (max-width: 500px) {
    .Container {
        padding: 1rem;
    }
    .button {
        font-size: 3.5vw;
        padding: 0.3rem;
    }
}
