.Activity-feed h3 {
    background-color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.Container {
    padding: 1rem 2rem;
    display: grid;
    gap: 1rem;
    align-content: baseline;
    min-height: 87.3vh;
    height: 100%;
    background-color: #ebf2fa;
}
.Activity-feed {
    display: grid;
    border: 1px solid #ddd;
    background-color: white;
    flex-grow: 1;
}
.Activity-feed h3 {
    padding: 0.3rem;
    border-bottom: 1px solid #ddd;
}
.Activity-feed p {
    padding: 0.3rem;
    color: #551a8b;
    font-size: 1rem;
    border-bottom: 1px solid #ddd;
}
.Activity-feed a {
    text-decoration: none;
}
.Activity-feed ul {
    list-style-type: none;
    padding: 0.3rem;
}

.Activity-feed li {
    padding: 0.3rem 0;
    font-size: 0.9rem;
    border-bottom: 1px solid #ddd;
    display: grid;
    justify-content: space-between;
    gap: 0.2rem;
}
.Activity-feed li span:nth-child(2) {
    font-size: 0.8rem;
    color: gray;
}
.Activity-feed li:last-child {
    border: none;
}
.Activity-feed a:hover {
    text-decoration: underline;
}
.breadcrumb {
    max-width: 768px;
    padding: 0.5em 1em;
    background-color: #edeff0;
    border-radius: 0.25em;
}

.breadcrumb::after {
    clear: both;
    content: "";
    display: table;
}

.breadcrumb .current {
    color: #96c03d;
}

.breadcrumb li {
    display: inline-block;
    margin: 0.5em 0;
}

.breadcrumb li::after {
    display: inline-block;
    content: "\00bb";
    margin: 0 0.2em;
    color: #959fa5;
}

.breadcrumb li:last-of-type::after {
    display: none;
}

.breadcrumb li * {
    display: inline-block;
    font-size: 1.4rem;
    color: #2c3f4c;
}

.breadcrumb span {
    font-size: 0.9rem;
    padding-bottom: 0.3rem;
}

.breadcrumb p {
    border-bottom: 2px solid #ccc;
}
.breadcrumb {
    /* reset basic style */
    background-color: transparent;
    padding: 0;
}
.breadcrumb li {
    position: relative;
    padding: 0;
    margin: 4px 4px 4px 0;
    text-transform: capitalize;
}
.breadcrumb li:last-of-type {
    margin-right: 0;
}
.breadcrumb li > * {
    position: relative;
    padding: 0.5rem;
    color: #2c3f4c;
    background-color: #edeff0;
    /* the border color is used to style its ::after pseudo-element */
    border-color: #edeff0;
}
.breadcrumb li.current > * {
    /* selected step */
    color: #ffffff;
    background-color: #96c03d;
    border-color: #96c03d;
}
.breadcrumb li:first-of-type > * {
    padding-left: 1.6em;
    border-radius: 0.25em 0 0 0.25em;
}
.breadcrumb li:last-of-type > * {
    padding-right: 1.6em;
    padding-left: 1.6em;

    border-radius: 0 0.25em 0.25em 0;
}
.no-touch .breadcrumb a:hover {
    /* steps already visited */
    color: #ffffff;
    background-color: #2c3f4c;
    border-color: #2c3f4c;
}
.breadcrumb li::after,
.breadcrumb li > *::after {
    /* 
    	li > *::after is the colored triangle after each item
    	li::after is the white separator between two items
    */
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    content: "";
    height: 0;
    width: 0;
    /* 48px is the height of the <a> element */
    border: 16px solid transparent;
    border-right-width: 0;
    border-left-width: 20px;
}
.breadcrumb li::after {
    /* this is the white separator between two items */
    z-index: 1;
    -webkit-transform: translateX(4px);
    -moz-transform: translateX(4px);
    -ms-transform: translateX(4px);
    -o-transform: translateX(4px);
    transform: translateX(4px);
    border-left-color: #ffffff;
    /* reset style */
    margin: 0;
}
.breadcrumb li > *::after {
    /* this is the colored triangle after each element */
    z-index: 2;
    border-left-color: inherit;
}
.breadcrumb li:last-of-type::after,
.breadcrumb li:last-of-type > *::after {
    /* hide the triangle after the last step */
    display: none;
}
.breadcrumb.custom-separator li::after {
    /* reset style */
    background-image: none;
}
.breadcrumb.custom-icons li::after,
.breadcrumb.custom-icons li > *::after {
    /* 50px is the height of the <a> element */
    border-top-width: 25px;
    border-bottom-width: 25px;
}

@-moz-document url-prefix() {
    .breadcrumb li::after,
    .breadcrumb li > *::after {
        /* fix a bug on Firefix - tooth edge on css triangle */
        border-left-style: dashed;
    }
}

@media screen and (max-width: 450px) {
    .breadcrumb {
        display: none;
    }
}
