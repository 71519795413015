.Container {
    padding: 1rem 2rem;
    display: grid;
    gap: 2rem;
    align-content: baseline;
    min-height: 87.3vh;
    height: 100%;
    background-color: #ebf2fa;
}
.control-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.control_Buttons {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    color: #0077b6;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 1rem;
}

.button:hover {
    background-color: #ececec;
}
.Tutor {
    padding: 15px 15px 40px;
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    width: 90%;
}
.Tutor h3 {
    border-bottom: 1px solid #ddd;
    padding: 0 0 5px;
    margin: 0 0 15px;
    font-size: 1.3rem;
    display: block;
    font-weight: 500;
    line-height: 1.2;
    font-family: inherit;
    color: #212529;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-align: left;
}
.Tutor > h2 {
    background-color: #1e87db;
    color: #fff;
    font-size: 14px;
    width: fit-content;
    padding: 0.3rem 0.5rem;
    text-align: center;
    border-radius: 5px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
}
.Navigator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.085);
}
.Navigator button {
    min-height: 46px;
    padding: 12px 16px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 400;
    background-color: white;
    cursor: pointer;
    color: #22527b;
}
.Navigator button:hover {
    border-color: #dee2e6 #dee2e6 #f9f9f9;
}
.Navigator button span {
    margin-left: 4px;
    background-color: #777;
    color: #fff;
    font-weight: 500;
    border-radius: 10rem;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}
.active {
    border-color: #dee2e6 #dee2e6 #f9f9f9 !important;
    background-color: #f9f9f9 !important;
}
.cards {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.Table {
    display: grid;
    width: 100%;
    overflow: auto;
}
.Table table {
    border-collapse: collapse;
    table-layout: auto;
    /* width: 100%; */
    min-width: 700px;
    text-align: left;
    border: 1px solid #ccc;
}
.Table table thead {
    color: white;
}
.Table table thead th {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    background-color: #1e87db;
    padding: 1rem 0rem;
    /* letter-spacing: 0.05rem; */
    text-transform: uppercase;
    vertical-align: middle;
    text-align: center;
}
.Table table tbody tr {
    text-align: center;
    cursor: pointer;
    color: #777;
}
.Table table tbody tr td {
    border-bottom: 1px solid #ccc;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    padding: 0.8rem 0rem;
    color: inherit;
    cursor: pointer;
    text-transform: capitalize;
}
/* .Table table tbody tr:hover {
 background-color: #c9c9c9;
 color: white;
} */
.Action-btns {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}
.Action-btns button {
    border: none;
    background-color: inherit;
    font-size: 1.4em;
    color: #0077b6;
    cursor: pointer;
}
.Status-active span {
    background-color: #def7ec;
    color: #0e9f6e;
    padding: 0.5rem;
}
.Status-inactive span {
    background-color: #fde8e8;
    color: #f05252;
}
.schedule-class {
    border: none;
    background-color: inherit;
    color: #1e87db;
    cursor: pointer;
    text-decoration: underline;
}

.modal {
    /* Default styles */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;

    width: 100%;
    max-width: 600px;
    overflow: auto;
    animation: bounce 0.5s ease;
    /* // animation-fill-mode: forwards; */
    outline: none;
}
.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;
}
.modal_Container {
    display: grid;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}
.modal_Container header {
    position: relative;
    padding: 5px 10px;
    border-bottom: 1px solid lightgray;
}
.control_Buttons button {
    font-size: 1rem;
    border: none;

    color: white;
    justify-self: center;
    width: fit-content;
    margin-bottom: 0.5rem;
    padding: 0.2rem 0.5rem;

    cursor: pointer;
}
.control_Buttons .resolve {
    background-color: #0e9f6e;
}
.control_Buttons .close {
    background-color: red;
}
.modal_Container header h3 {
    text-align: center;
    font-family: "poppins" sans-serif;
    font-weight: 550;
    font-size: 1.3rem;
}
.Complaint_Details {
    display: grid;
    gap: 1rem;
    padding: 0.5rem 1rem;
}
.Complaint_Details .Complaint_Details_sub {
    display: grid;
    grid-template-columns: 30% 1fr;
    align-items: baseline;
    gap: 0.5rem 1rem;
}
.Complaint_Details textarea {
    outline: none;
    resize: none;
    border-color: gray;
    padding: 0.5rem;
    border-radius: 10px;
    font-family: inherit;
}
.loader {
    justify-self: center;
}
.Complaint_Details div h3 {
    font-family: sans-serif;
    font-weight: 550;
    font-size: 1rem;
}
.Complaint_Details div p {
    word-wrap: break-word;
    word-break: break-all;
    width: fit-content;
}
.Complaint_Details ul {
    list-style-type: none;
}
.Complaint_Details div:last-child {
    padding-bottom: 0.4rem;
    border-bottom: 1px solid #ddd;
}
.modal:has(.loader) {
    cursor: progress;
}
.pending {
    color: #fde8e8;
    background-color: #d78686;
    width: fit-content;
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
}
.resolved {
    background-color: #def7ec;
    width: fit-content;
    color: #0e9f6e;
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
}
@media screen and (max-width: 768px) {
    .modal {
        width: 80%;
    }
    .Complaint_Details div {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 480px) {
    .modal {
        width: 60%;
    }
    .overlay {
        background-color: rgba(0, 0, 0, 0.8);
    }
}
@media screen and (max-width: 600px) {
    .Container {
        padding: 1rem;
    }
}
@keyframes bounce {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
        transform: translate(-50%, -50%) scale(0.95);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}
