.Container h3 {
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.3rem;
    font-size: 15px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    color: #212529;
    text-align: left;
}
.Container table {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top: none;
    border-collapse: collapse;
}

.Container table th {
    border: 1px solid rgba(0, 0, 0, 0.125);
    font-size: 15px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    color: #212529;
    text-align: left;
    padding: 0.7rem;
}
.Container table tr td {
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.7rem;
    color: #212529;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
}
.Container table tr:hover {
    background-color: lightgray;
}
@media screen and (max-width: 500px) {
    .Container table th,
    .Container table tr td,
    .Container h3 {
        padding: 0.3rem;
        font-size: 3.2vw;
    }
}
