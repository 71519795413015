.Container {
    border: 1px solid #ddd;
}
.Container h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.3rem;
    font-size: 15px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    color: #212529;
    text-align: left;
}
/* .Container p {
    padding: 1rem 1rem 0 1rem;
} */
.Container header {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
}
.Container header h4 {
    background-color: inherit;
    font-weight: 500;
}
.Container header div {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}
.Container header div button {
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    cursor: pointer;
}
.Container header div button:disabled {
    color: lightgray;
    cursor: not-allowed;
}

.Container header h4 span {
    font-weight: bold;
    font-size: 0.9rem;
    padding: 0.3rem;
    color: white;
    border-radius: 10px;
    background-color: rgb(78, 131, 78);
}

.form {
    display: flex;
    flex: 1;
    gap: 1rem;
    align-items: center;
}
.form input {
    text-indent: 0.3rem;
    border: 1px solid lightgray;
    height: 2rem;
    border-radius: 10px;

    width: 100%;
    max-width: 7rem;
    /* font-size: 1rem; */
    text-transform: uppercase;
}
.form button {
    color: white;
    border: 1px solid #0077b6;
    background-color: #0077b6;
    width: -moz-fit-content;
    width: fit-content;
    align-self: flex-end;
    padding: 0.4rem 1rem;
    border-radius: 10px;
    cursor: pointer;
}

.Table {
    display: grid;
    width: 100%;
    overflow: auto;
}
.Table table {
    border-collapse: collapse;
    table-layout: auto;
    /* width: 100%; */
    min-width: 700px;
    text-align: left;
    border: 1px solid #ccc;
}
.Table table thead {
    color: white;
    border-bottom: 1px solid #ccc;
}
.Table table thead th {
    padding: 0.3rem;
    font-size: 15px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    color: #212529;
    text-align: center;
}
.Table table tbody tr {
    text-align: center;
    cursor: pointer;
    color: #777;
}
.Table table tbody tr td {
    border-bottom: 1px solid #ccc;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    padding: 1rem 0rem;
    user-select: none;
    color: inherit;
    cursor: pointer;
    text-transform: capitalize;
}
.Table table tbody tr:hover {
    background-color: #c9c9c9;
    color: white;
}
.paid {
    color: white;
    background-color: rgb(45, 139, 45);
    border-radius: 5px;
    border: 1px solid rgb(45, 139, 45);
    padding: 0.3rem 0.5rem;
}
.unpaid {
    color: white;
    background-color: rgb(187, 86, 86);
    border-radius: 5px;
    border: none;
    padding: 0.2rem 0.5rem;
}
.loading td {
    padding: 1rem 0.5rem !important;
}
.Payout-details {
    display: grid;
    gap: 0.2rem;
}
.payout_data {
    /* justify-self: right; */
    padding: 0.5rem;
}
.Payout-details header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.5rem;
    border: 1px solid #ddd;
}
.Payout-details header h3 {
    border: none;
}
.Payout-details header button {
    border: 1px solid #ddd;
    cursor: pointer;
    padding: 0.5rem;
    color: #0077b6;
    background-color: white;
}
.Payout-details header button:hover {
    background-color: #ddd;
}
span {
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
}
.payout_data div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.payout_data div h4 {
    font-family: inherit;
    font-weight: 500;
}
.Container_2 {
    border: none;
}
.details_wrapper {
    border: 1px solid #ddd;
}

.breadcrumb {
    margin-bottom: 0.5rem;
    max-width: 768px;
    /* padding: 0.5em 1em; */
    background-color: #edeff0;
    border-radius: 0.25em;
}

.breadcrumb::after {
    clear: both;
    content: "";
    display: table;
}

.breadcrumb .current {
    color: #96c03d;
}

.breadcrumb li {
    display: inline-block;
    margin: 0.5em 0;
}

.breadcrumb li::after {
    display: inline-block;
    content: "\00bb";
    margin: 0 0.2em;
    color: #959fa5;
}

.breadcrumb li:last-of-type::after {
    display: none;
}

.breadcrumb li * {
    display: inline-block;
    font-size: 1.4rem;
    color: #2c3f4c;
}

.breadcrumb span {
    font-size: 0.9rem;
    padding-bottom: 0.3rem;
}

.breadcrumb p {
    border-bottom: 2px solid #ccc;
}
.breadcrumb {
    /* reset basic style */
    background-color: transparent;
    padding: 0;
}
.breadcrumb li {
    position: relative;
    padding: 0;
    margin: 4px 4px 4px 0;
    text-transform: capitalize;
}
.breadcrumb li:last-of-type {
    margin-right: 0;
}
.breadcrumb li > * {
    position: relative;
    padding: 0.5rem;
    color: #2c3f4c;
    background-color: #edeff0;
    /* the border color is used to style its ::after pseudo-element */
    border-color: #edeff0;
}
.breadcrumb li.current > * {
    /* selected step */
    color: #ffffff;
    background-color: #96c03d;
    border-color: #96c03d;
}
.breadcrumb li:first-of-type > * {
    padding-left: 1.6em;
    border-radius: 0.25em 0 0 0.25em;
}
.breadcrumb li:last-of-type > * {
    padding-right: 1.6em;
    padding-left: 1.6em;

    border-radius: 0 0.25em 0.25em 0;
}
.no-touch .breadcrumb a:hover {
    /* steps already visited */
    color: #ffffff;
    background-color: #2c3f4c;
    border-color: #2c3f4c;
}
.breadcrumb li::after,
.breadcrumb li > *::after {
    /* 
    	li > *::after is the colored triangle after each item
    	li::after is the white separator between two items
    */
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    content: "";
    height: 0;
    width: 0;
    /* 48px is the height of the <a> element */
    border: 16px solid transparent;
    border-right-width: 0;
    border-left-width: 20px;
}
.breadcrumb li::after {
    /* this is the white separator between two items */
    z-index: 1;
    -webkit-transform: translateX(4px);
    -moz-transform: translateX(4px);
    -ms-transform: translateX(4px);
    -o-transform: translateX(4px);
    transform: translateX(4px);
    border-left-color: #ffffff;
    /* reset style */
    margin: 0;
}
.breadcrumb li > *::after {
    /* this is the colored triangle after each element */
    z-index: 2;
    border-left-color: inherit;
}
.breadcrumb li:last-of-type::after,
.breadcrumb li:last-of-type > *::after {
    /* hide the triangle after the last step */
    display: none;
}
.breadcrumb.custom-separator li::after {
    /* reset style */
    background-image: none;
}
.breadcrumb.custom-icons li::after,
.breadcrumb.custom-icons li > *::after {
    /* 50px is the height of the <a> element */
    border-top-width: 25px;
    border-bottom-width: 25px;
}

@-moz-document url-prefix() {
    .breadcrumb li::after,
    .breadcrumb li > *::after {
        /* fix a bug on Firefix - tooth edge on css triangle */
        border-left-style: dashed;
    }
}

@media screen and (max-width: 450px) {
    .breadcrumb {
        display: none;
    }
}
