.Container {
    padding: 1rem 2rem;
    display: grid;
    gap: 2rem;
    align-content: baseline;
    min-height: 87.3vh;
    height: 100%;
    background-color: #ebf2fa;
}
.filters {
    z-index: 5;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 1rem;
}
.date-filter {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 1;
}
.date-filter input {
    border: 1px solid #ccc;
    height: 2.3rem;
    font-size: 1rem;
    text-indent: 0.5rem;
    text-transform: uppercase;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
}
.date-filter span {
    color: red;
}
.date-filter button {
    color: white;
    border: 1px solid #0077b6;
    background-color: #0077b6;
    width: fit-content;
    align-self: flex-end;
    padding: 0.6rem 1rem;
    border-radius: 10px;
    cursor: pointer;
}
.date-filter .clear {
    background-color: red !important;
    border: none;
}
.form-control {
    max-width: 250px;
    width: 95%;
    position: relative;
    display: grid;
    gap: 0.5rem;
}
.form-control input,
.form-control select,
.select {
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    height: 2.5re3;
    /* padding: 8px; */
    width: 100%;
    cursor: pointer;
}
.tutor {
    padding: 15px 15px 40px;
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    width: 90%;
}
.tutor h3 {
    border-bottom: 1px solid #ddd;
    padding: 0 0 5px;
    margin: 0 0 15px;
    font-size: 1.3rem;
    display: block;
    font-weight: 500;
    line-height: 1.2;
    font-family: inherit;
    color: #212529;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-align: left;
}
.calender {
    min-height: 75vh;
    height: 100%;
}
