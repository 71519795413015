.modal {
    /* Default styles */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 0;
    width: 700px;
    overflow: auto;

    /* Media queries for responsive adjustments */
    @media (max-width: 768px) {
        width: 80%;
    }

    @media (max-width: 480px) {
        width: 60%;
    }
}
.wrapper {
    display: grid;
    gap: 0.5rem;
    margin: 0.5rem;
}
.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;

    /* Media queries for responsive adjustments */
    @media (max-width: 480px) {
        background-color: rgba(0, 0, 0, 0.8);
    }
}
.wrapper header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ccc;
}
.wrapper header h3 {
    font-size: 1.2rem;
    font-weight: 500;
    font-family: sans-serif;
}
.wrapper header button {
    border: none;
    background-color: inherit;
    font-size: 1rem;
    cursor: pointer;
}
.wrapper form {
    display: grid;
    gap: 0.5rem;
    padding: 0 1rem;
}
.form-control {
    display: grid;
    gap: 0.5rem;
}
.form-control > label {
    font-size: 1.1rem;
    font-weight: normal;
}
.form-control select,
.form-control input {
    border: 1px solid #ccc;
    outline: none;
    height: 2.5rem;
    text-indent: 1rem;
    width: 100%;
}
.Control-btns {
    margin-top: 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-self: center;
}
.Control-btns button {
    width: 4rem;
    height: 2rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    border: none;
    color: white;
}
.Control-btns button:first-child {
    background-color: red;
}
.Control-btns button:nth-child(2) {
    background-color: rgb(48, 48, 140);
}
.error {
    color: red;
    font-size: 0.9rem;
    font-weight: 550;
}
.input-error {
    border: 1px solid red !important;
}
.checkbox {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    padding: 0 0 0 1rem;
}
.checkbox label {
    display: flex;
    gap: 0.3rem;
    align-items: center;
    cursor: pointer;
}
.form-control-select {
    display: grid;
    gap: 0.5rem;
}
.radio-btns {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-left: 1rem;
}
.radio-btns label {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
}
