/* Default styles */
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
    background-color: white;
    padding: 0 20px;
    width: 500px;
    overflow: auto;
    /* Media queries for responsive adjustments */
}
@media (max-width: 908px) {
    .modal {
        width: 80%;
    }
}
@media (max-width: 480px) {
    .modal {
        width: 60%;
        transform: translate(-40%, -70%);
    }
}

.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    /* Media queries for responsive adjustments */
}
@media (max-width: 480px) {
    .overlay {
        background-color: rgba(0, 0, 0, 0.8);
    }
}
.form-wrapper {
    padding: 10px 0;
    display: grid;
    gap: 1rem;
    width: 100%;
    position: relative;
}
.form-wrapper > button {
    position: absolute;
    right: -10px;
    top: 10px;
    font-size: 1.5rem;
    border: none;
    background-color: inherit;
    cursor: pointer;
}
.form-wrapper h2 {
    text-align: center;
    border-bottom: 1px solid #ccc;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    padding: 0.3rem 0;
}
.form-wrapper form {
    display: grid;
    grid-template-columns: 1fr;
    flex-wrap: wrap;
    gap: 2rem;
    gap: 0.5rem;
}
.form-wrapper form .form-control {
    position: relative;
    display: grid;
    gap: 0.4rem;
    /* Firefox */
}

.form-wrapper form .form-control input,
.form-wrapper form .form-control select {
    height: 2rem;
    border: 1px solid lightgray;
    border-radius: 5px;
    height: 3rem;
    outline: none;
    font-size: 1rem;

    width: 100%;
    user-select: none;
    text-indent: 0.6rem;
}

.form-wrapper form .form-control .error {
    color: red;
}

.form-wrapper form .input-number select {
    width: 100%;
    color: black;
    font-size: 0.8rem;
    text-indent: 0.3rem;
    height: 90%;
    text-align: center;
    border: none;
    background-color: inherit;
}
.buttons {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    margin-top: 0.5rem;
    grid-column: 1/-1;
}
.form-wrapper form .form-control:last-child(-1) {
    display: none;
}
.submit {
    color: white;
    background-color: #1e87db;
    text-align: center;
    padding: 1rem 3rem;
    text-align: 1.5rem;
    border-radius: 10px;
    border: 1px solid;
    cursor: pointer;
    justify-self: center;
    letter-spacing: 0.06rem;
}
.close {
    cursor: pointer;
    border: 1px solid lightgray;
    background-color: lightgray;
    border-radius: 5px;
    color: black;
    padding: 1rem 0;
}
.popup-actions {
    display: flex;
    gap: 0.3rem;
    justify-self: right;
    justify-content: flex-end;
    margin: 0.4rem 0.5rem 0.1rem 0.5rem;
}
.logout-popup {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    font-size: 1rem;
    text-align: center;
}
@media screen and (max-width: 900px) {
    .form-wrapper form {
        display: grid;
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 500px) {
    .form-wrapper form input,
    .form-wrapper form select {
        width: 100% !important;
    }
}
