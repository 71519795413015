.wrapper {
    padding: 1rem;
    display: grid;
    gap: 1rem;
    background-color: #ebf2fa;
    height: 100%;
    align-content: baseline;
    min-height: 89vh;
}
.wrapper header {
    justify-self: right;
    display: flex;
    gap: 1rem;
}
.wrapper header button {
    padding: 0.5rem 1rem;
    /* height: 3rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #061739;
    color: white;
    font-family: inherit;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: 500ms;
}

.wrapper header select {
    outline: none;
    border: 1px solid #ccc;
    padding: 0.4rem;
    height: 2.5rem;
    cursor: pointer;
}
.Table {
    display: grid;
    overflow: auto;
    background-color: white;
    padding: 0.5rem;
}
.Table table {
    border-collapse: collapse;
    table-layout: auto;
    /* width: 100%; */
    min-width: 900px;
    text-align: left;
    border: 1px solid #ccc;
}
.Table table thead {
    color: white;
}
.Table table thead th {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    background-color: #1e87db;
    padding: 1rem 0.5rem;
    /* letter-spacing: 0.05rem; */
    text-transform: uppercase;
    vertical-align: middle;
}
.Table table tbody tr {
    cursor: pointer;
    color: #777;
}
.Table table tbody tr td {
    border-bottom: 1px solid #ccc;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    padding: 1rem 0.5rem;
    color: inherit;
    cursor: pointer;
    text-transform: capitalize;
    word-wrap: break-word;
}
.Action-btns button {
    justify-self: center;
    background-color: #061739;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    vertical-align: center;
    cursor: pointer;
    border: 1px solid transparent;
}
.Action-btns button:hover {
    color: #061739;
    background-color: white;
    border: 1px solid #061739;
}
.Action-btns button:nth-child(2) {
    background-color: red;
}
.Action-btns button:nth-child(2):hover {
    background-color: white;
    color: red;
    border-color: red;
}
.Action-btns {
    align-items: center;
    justify-self: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}
