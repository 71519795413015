.Skeleton-Container {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    padding: 1rem;
}
.contact {
    display: flex;
    gap: 1rem;
}
.skeleton-left {
    border-right: 1px solid lightgray;
    padding: 0 1rem;
}
.skeleton-rightside {
    padding-left: 1rem;
}
.content {
    padding: 1rem;
    border: 1px solid #ddd;
}
.profile-data {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
    gap: 1rem;
}
.profile-data > div {
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    border-radius: 2px;
    border: 1px solid #ddd;
    margin: 5px 0 10px;
    background: #fff;
}
.profile-data div h3,
.profile-data div p {
    padding: 0.5rem;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.profile-data div p {
    font-weight: 400;
    font-size: 0.8;
}

.profile-data div span {
    padding: 0.5rem;
}
.read {
    background-color: green;
    color: white;
    font-size: 0.8rem;
    width: fit-content !important;
    padding: 0.4rem 0.5rem !important;
}
.write {
    background-color: blue;
    color: white;
    font-size: 0.8rem;
    width: fit-content !important;
    padding: 0.4rem 0.5rem !important;
}
.edit {
    background-color: red;
    color: white;
    font-size: 0.8rem;
    width: fit-content !important;
    padding: 0.4rem 0.5rem !important;
}
.permission_single {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    border-bottom: 1px solid #ccc;
}
.permission_single p {
    display: flex;
    gap: 0.5rem;
}
.Permissions {
    display: grid;
    padding: 0.5rem;
    gap: 0.5rem;
}
@media screen and (max-width: 767px) {
    .Skeleton-Container {
        grid-template-columns: 1fr;
        padding: 0;
    }
    .skeleton-left {
        border: none;
    }
    .skeleton-rightside {
        display: none;
    }
    .profile-data {
        grid-template-columns: 1fr;
    }
}
