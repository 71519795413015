.Container h3 {
    padding: 0.3rem;
    font-size: 15px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    color: #212529;
    text-align: left;
}
.Status-active {
    background-color: #def7ec;
    color: #0e9f6e;
    padding: 0.5rem;
    border-radius: 10px;
}
.Status-inactive {
    padding: 0.5rem;
    border-radius: 10px;
    background-color: #fde8e8;
    color: #f05252;
}
.Container header {
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.Container header div {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.Container header button {
    border: none;
    background-color: green;
    color: white;
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    margin: 0.2rem 0.5rem;
}
.balance {
    display: flex !important;
    width: fit-content !important;
    background-color: inherit !important;
    font-weight: 550;
}
.Container {
    display: grid;
    width: 100%;
    overflow: auto;
}
.Container table {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.125);
    table-layout: auto;
    min-width: 400px;
    border-top: none;
    border-collapse: collapse;
}

.Container table th {
    border: 1px solid rgba(0, 0, 0, 0.125);
    font-size: 15px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    color: #212529;
    text-align: left;
    padding: 0.7rem;
}
.Container table tr td {
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.7rem;
    color: #212529;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
}
@media screen and (max-width: 500px) {
    .Container table th,
    .Container table tr td,
    .Container h3 {
        padding: 0.3rem;
        font-size: 3.2vw;
    }
}
