.container {
    padding: 1rem 2rem;
    display: grid;
    gap: 1rem;
    align-content: baseline;
    min-height: 87.3vh;
    height: 100%;
    background-color: #ebf2fa;
}
.edit-class {
    display: flex;
    gap: 0.2rem;
}
.edit-class input {
    width: 90%;
    padding: 0.2rem;
    text-indent: 0.3rem;
    border: 1px solid #ccc;
    outline: none;
}
.edit-class button {
    width: fit-content;
    border: none;
    background-color: inherit;
    color: red;
    font-weight: 550;
    cursor: pointer;
}
.back {
    width: fit-content;
    border: 1px solid #ccc;
    padding: 0.5rem;
    background-color: white;
    color: black;
    cursor: pointer;
}
.back:hover {
    background-color: lightgray;
}
.control-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}
.Navigator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.085);
}
.Navigator button {
    min-height: 46px;
    padding: 12px 16px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 400;
    background-color: white;
    cursor: pointer;
    color: #22527b;
}
.Navigator button:hover {
    border-color: #dee2e6 #dee2e6 #f9f9f9;
}
.Navigator button span {
    margin-left: 4px;
    background-color: #777;
    color: #fff;
    font-weight: 500;
    border-radius: 10rem;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}
.active {
    border-color: #dee2e6 #dee2e6 #f9f9f9 !important;
    background-color: #f9f9f9 !important;
}
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    border: 1px solid #ccc;

    background-color: white;
    color: #0077b6;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 1rem;
}
.Delete {
    background-color: red;
}

.button:hover {
    background-color: #ececec;
}
.Navigator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.085);
}
.Navigator button {
    min-height: 46px;
    padding: 12px 16px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 400;
    background-color: white;
    cursor: pointer;
    color: #22527b;
}
.Navigator button:hover {
    border-color: #dee2e6 #dee2e6 #f9f9f9;
}
.Navigator button span {
    margin-left: 4px;
    background-color: #777;
    color: #fff;
    font-weight: 500;
    border-radius: 10rem;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}
.active {
    border-color: #dee2e6 #dee2e6 #f9f9f9 !important;
    background-color: #f9f9f9 !important;
}
.wrapper {
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    align-content: baseline;
    gap: 1rem;
    padding: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    width: 95%;
    height: 100%;
    min-height: 18rem;
}
.wrapper h3 {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-weight: 500;
    color: #0077b6;
    text-transform: capitalize;
}
.wrapper h3 span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    background-color: SandyBrown;
}
.profile {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.initials {
    border: 1px solid #ddd;
    height: 90px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0.5rem; */
}
.initials h4 {
    padding: 5px;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 2.9rem;
    font-weight: 400;
    text-align: center;

    text-transform: uppercase;
}
.Contact {
    padding: 0.5rem;
    display: flex;
    gap: 1.4rem;
    border-right: 1px solid #ddd;
}
.card-data h4 {
    margin-bottom: 0.5rem;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
}
.card-data {
    display: grid;
    align-items: start;
    align-content: start;
}
.card-data p:nth-child(2) {
    color: #016997;
    margin-bottom: 0.2rem;
}
.card-data p:nth-child(2) span {
    margin-bottom: 0.2rem;
}
.card-data p {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}
.ongoing {
    padding: 0.5rem;
    color: white;
    background-color: #0077b6;
    cursor: pointer;
    border: 1px solid #0077b6;
    border-radius: 10px;
}
.action-btn {
    width: 100px;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    justify-content: center;
    margin-top: 0.4rem;
    cursor: pointer;
    padding: 0.3rem 0;
    color: #fff;
    background: #186bae !important;
    font-size: 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid transparent;
}
.internal {
    padding: 0.5rem;
    display: grid;
    align-content: start;
    gap: 0.3rem;
}
.internal > h3 {
    font-weight: 700;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    margin-bottom: 1rem;
}
.internal div {
    display: grid;
    grid-template-columns: 0.7fr 0.3fr 1fr;
    align-items: baseline;
}
.internal div h3,
.internal p {
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.Table {
    display: grid;
    width: 100%;
    overflow: auto;
}
.Table h3 {
    margin-bottom: 1rem;
}
.Table table {
    border-collapse: collapse;
    table-layout: auto;
    /* width: 100%; */
    min-width: 700px;
    text-align: left;
    border: 1px solid #ccc;
}
.Table table thead {
    color: white;
}
.Table table thead th {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    background-color: #1e87db;
    padding: 1rem 0rem;
    /* letter-spacing: 0.05rem; */
    text-transform: uppercase;
    vertical-align: middle;
    text-align: center;
}
.Table table tbody tr {
    text-align: center;
    color: #777;
}
.Table table tbody tr td {
    border-bottom: 1px solid #ccc;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    padding: 1rem 0rem;
    color: inherit;
    text-transform: capitalize;
}
.Table table tbody tr:hover {
    background-color: #c9c9c9;
    color: white;
}
.Class-count {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.delete {
    border: none;
    font-size: 1.1rem;
    background-color: inherit;
    cursor: pointer;
}
.student-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.student-select button {
    background-color: #3c5670;
    color: white;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
}
.student-select button:disabled {
    background-color: lightgray;
    border: none;
    cursor: not-allowed;
}
.cancelled {
    color: white;
    background-color: red;
    padding: 0.4rem;
    border-radius: 5px;
}
.active-class,
.completed {
    color: white;
    background-color: rgb(227, 175, 129);
    padding: 0.4rem;
    border-radius: 5px;
}
.completed {
    background-color: green;
}
.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
}
.edit-price {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.price-input {
    width: 5rem;
    outline: none;
    height: 2rem;
    text-indent: 0.3rem;
}
.resend {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.resend button {
    border: none;
    color: white;
    background-color: red;
    cursor: pointer;
    padding: 0.3rem;
    border-radius: 5px;
}
.resend button:hover {
    background-color: white;
    color: red;
    border: 1px solid red;
}
.scheduleclass-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.scheduleclass-header button {
    color: white;
    border: none;
    cursor: pointer;
    background-color: red;
    border-radius: 10px;
    padding: 0.5rem;
}
.batch-btns {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}
.batch-btns button:first-child {
    padding: 0.5rem;
    background-color: green !important;
}
.tutorLink {
    border: none;
    background-color: inherit;
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
}
@media screen and (max-width: 900px) {
    .profile {
        grid-template-columns: 1fr;
    }
    .Contact {
        border: none;
    }
    .internal div {
        width: 90%;
    }
}
@media screen and (max-width: 650px) {
    .internal div {
        grid-template-columns: 1fr;
    }
    .internal div span {
        display: none;
    }
    .internal div h3 {
        font-weight: bold;
    }
}
@media screen and (max-width: 500px) {
    .Contact {
        display: grid;
    }
}
