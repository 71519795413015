.Activity-feed h3 {
    background-color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.Activity-feed {
    display: grid;
    border: 1px solid #ddd;
    flex-grow: 1;
}
.Activity-feed h3 {
    padding: 0.3rem;
    border-bottom: 1px solid #ddd;
}
.Activity-feed p {
    padding: 0.3rem;
    color: #551a8b;
    font-size: 1rem;
    border-bottom: 1px solid #ddd;
}
.Activity-feed a {
    text-decoration: none;
}
.Activity-feed ul {
    list-style-type: none;
    padding: 0.3rem;
}

.Activity-feed li {
    padding: 0.3rem 0;
    font-size: 0.9rem;
    border-bottom: 1px solid #ddd;
    display: grid;
    justify-content: space-between;
    gap: 0.2rem;
}
.Activity-feed li span:nth-child(2) {
    font-size: 0.8rem;
    color: gray;
}
.Activity-feed li:last-child {
    border: none;
}
.Activity-feed a:hover {
    text-decoration: underline;
}
