.Container {
    display: grid;
    align-items: center;
    justify-items: center;
    margin: 2rem;
    height: 100%;
    width: 300px;
}
.Container h3 {
    font-size: 1.3rem;
    line-height: 1.8rem;
    text-align: center;
    color: gray;
    font-weight: 550;
    font-family: "Open Sans", sans-serif;
}

.modal {
    /* // display: flex; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    overflow: auto;
    border-radius: 10px;
    animation: bounce 0.5s ease;
    animation-fill-mode: forwards;
    outline: none;
    opacity: 0;
    background-color: white;
    width: 400px;
}
.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
}
.wrapper {
    display: grid;
    gap: 2rem;
    align-content: baseline;
    align-items: center;
    padding: 1rem;
    padding-top: 0.5rem;
    font-family: "Poppins", sans-serif;
}
.wrapper h3 {
    border-bottom: 1px solid #ccc;
    text-align: center;
    padding: 0.5rem 0 1rem 0;
}
.form-control {
    display: grid;
    gap: 0.5rem;
    align-content: baseline;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
}
.form-control label {
    font-family: inherit;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.form-control input,
.form-control select,
.form-control textarea {
    display: block;
    width: 93%;
    height: 1.6rem;
    padding: 0.375rem 0.75rem;

    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    outline: none;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
}
.form-control textarea {
    padding: 0;
    width: 100% !important;
    resize: none;
    text-indent: 0.5rem;
    height: unset;
    font-family: inherit;
}
.form-control input:focus,
.form-control select:focus {
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);

    border: 1px solid rgba(81, 203, 238, 1);
}
.form-control span {
    font-family: inherit;
    color: red;
}
.form-control input:disabled {
    background-color: lightgray;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.form-control select {
    height: 2.4rem;
    width: 100%;
}
.wrapper button {
    justify-self: center;
    /* color: white; */
    padding: 0.2rem 0.5rem;
    /* border: none; */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.8rem;
    cursor: pointer;
}
@keyframes bounce {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
        transform: translate(-50%, -50%) scale(0.95);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

/* Media queries for responsive adjustments */
@media (max-width: 768px) {
    .modal {
        width: 90%;
    }
}
